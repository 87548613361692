<template>
  <v-app>
    <v-navigation-drawer
      v-model="controls.drawer"
      app
      width="290"
      overflow
      data-testid="navigation-drawer"
    >
      <v-layout
        align-center
        justify-center
        column
        class="app-logo-container"
        style="height:180px"
      >
        <div class="px-5 full-width">
          <v-img :src="require('@/assets/images/logo.png')"></v-img>
        </div>
        <div class="title mt-4">BBR Admin CMS</div>
      </v-layout>

      <v-list>
        <template v-for="(item, itemIndex) in navItems">
          <v-divider
            v-if="item.children && item.active"
            :key="`${itemIndex}-first-divider`"
          />

          <v-list-group
            :class="{ 'grey lighten-5': item.active }"
            v-if="item.children"
            :key="itemIndex"
            v-model="item.active"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="pa-2 nav-item" v-text="item.label" />
              </v-list-item-content>
            </template>

            <v-list-item
              class="app-main-nav"
              v-for="(subItem, subItemmIndex) in item.children"
              :key="subItemmIndex"
              :to="subItem.to"
              v-ripple="{ class: 'primary--text lighten-4' }"
              @click="open(item.id)"
              ripple
              link
            >
              <v-list-item-content>
                <v-list-item-title
                  class="pa-2 pl-5 nav-item"
                  v-text="subItem.label"
                />
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item
            v-else
            class="app-main-nav"
            :key="itemIndex"
            :to="item.to"
            v-ripple="{ class: 'primary--text lighten-4' }"
            @click="open(item.id)"
            link
          >
            <v-list-item-content>
              <v-list-item-title class="pa-2 nav-item">
                {{ item.label }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider
            v-if="item.children && item.active"
            :key="`${itemIndex}-second-divider`"
          />
        </template>
      </v-list>

      <template v-slot:append>
        <v-list>
          <v-menu top offset-y>
            <template v-slot:activator="{ on }">
              <v-list-item two-line v-on="on" data-testid="user-menu-button">
                <v-list-item-avatar>
                  <user-avatar size="40" :avatar="user.avatar" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title> {{ user.full_name }} </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ user.email ? user.email : user.phone_number }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list>
              <v-list-item
                :to="{ name: 'admin.profile', params: { id: user.id } }"
                data-testid="user-menu-profile-link"
              >
                <v-list-item-title>Profile</v-list-item-title>
              </v-list-item>
              <v-list-item
                :to="{ name: 'admin.settings', params: { id: user.id } }"
                data-testid="user-menu-settings-link"
              >
                <v-list-item-title> Settings </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="logoutConfirmDialog = true"
                data-testid="user-menu-logout-link"
              >
                <v-list-item-title>Log out</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-main class="main-content white">
      <slot />
    </v-main>

    <v-dialog v-model="logoutConfirmDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Logout</v-card-title>
        <v-card-text>
          <strong>Are you sure you want to logout ?</strong>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            text
            @click="logoutConfirmDialog = false"
            data-testid="logout-modal-cancel-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="logout"
            data-testid="logout-modal-logout-button"
          >
            Logout
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import UserAvatar from '@/components/UserAvatar'

import { mapState, mapActions, mapGetters } from 'vuex'
import { each } from 'lodash'

export default {
  name: 'MainLayout',

  components: {
    UserAvatar,
  },

  mixins: [ControlsMixin],

  data() {
    return {
      item: 1,
      navItems: [
        { id: 1, label: 'Users', active: false, to: { name: 'users' } },
        { id: 1, label: 'Trainers', active: false, to: { name: 'trainers' } },
        {
          id: 2,
          label: 'Food',
          active: false,
          children: [
            { label: 'Database', to: { name: 'foods' } },
            { label: 'Swaps', to: { name: 'food-swap' } },
            { label: 'Recipes', to: { name: 'recipe' } },
            { label: 'Meal Guides', to: { name: 'meal-guides' } },
          ],
        },
        {
          id: 3,
          label: 'Challenges & Programs',
          active: false,
          children: [
            { label: 'Plans', to: { name: 'plans' } },
            { label: 'Programs', to: { name: 'programs' } },
            { label: 'Challenges', to: { name: 'challenges' } },
          ],
        },
        { id: 7, label: 'Workouts', active: false, to: { name: 'workouts' } },
        { id: 8, label: 'Exercises', active: false, to: { name: 'exercises' } },
        {
          id: 9,
          label: 'Video Contents',
          active: false,
          to: { name: 'videos' },
        },
        { id: 10, label: 'Promos', active: false, to: { name: 'promos' } },
        { id: 11, label: 'Mindset', active: false, to: { name: 'mindsets' } },
        {
          id: 14,
          label: 'Memberships',
          active: false,
          to: { name: 'memberships', query: { status: 'complete' } },
        },
        {
          id: 12,
          label: 'Reports',
          active: false,
          children: [
            { label: 'Sales', to: { name: 'sales' } },
            {
              label: 'User Scheduled for Deletion',
              to: { name: 'user-scheduled-deletion' },
            },
          ],
        },
        {
          id: 13,
          label: 'Settings',
          active: false,
          children: [
            {
              label: 'Food Categories',
              to: { name: 'settings.food-categories' },
            },
            {
              label: 'Workout Extra Categories',
              to: { name: 'settings.workout-extra-categories' },
            },
            {
              label: 'On Demand Categories',
              to: { name: 'settings.live-workout-categories' },
            },
            { label: 'Categories', to: { name: 'category-settings' } },
            {
              label: 'Training Programs',
              to: { name: 'settings.training-programs' },
            },
          ],
        },
      ],
      logoutConfirmDialog: false,
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    ...mapGetters({
      avatarThumb: 'auth/avatarThumb',
    }),
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),

    open(itemId) {
      each(this.navItems, (item) => {
        if (item.id !== itemId) {
          item.active = false
        }

        return item
      })
    },
  },
}
</script>
