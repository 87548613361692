import { mapState, mapMutations } from 'vuex'
import eventBus from '@/services/eventBus'

export default {
  data() {
    return {
      controls: {
        drawer: null,
      },
    }
  },

  computed: {
    ...mapState({
      showDrawer: (state) => state.controls.showDrawer,
    }),
  },

  methods: {
    ...mapMutations({
      toggleDrawer: 'toggleDrawer',
    }),

    registerEventListener() {
      eventBus.$on('TOGGLE_SIDEBAR', () => {
        this.controls.drawer = !this.controls.drawer
      })
    },
  },

  created() {
    this.controls.drawer = this.showDrawer
  },

  mounted() {
    this.registerEventListener()
  },

  watch: {
    showDrawer(val) {
      this.controls.drawer = val
    },
    'controls.drawer'(val) {
      this.toggleDrawer(val)
    },
  },
}
